<template>
        <div  class="inner-pages hd-white">
         <div id="wrapper">
         <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
          <h3 data-v-67fec170="" data-v-4f8ed385="" class="pase text-center" style="font-weight: 700; font-size: 45px;"> Login </h3>
        <div id="login">
            <div class="login">
                <form @submit.prevent="">
                    <div class="access_social">
                        <!-- <a @click="facebookLogin()" href="#0" class="social_bt facebook d-none">Continue with Facebook</a> -->
                        <a @click="googleLogin()" href="#0" class="social_bt google">Continue with Google</a>
                    </div>
                    <div class="divider"></div>
                    <div class="form-group">
                        <label>Email</label>
                        <input :class="($v.email.$dirty && !$v.email.required) ? 'error-border' : ''" v-model="email" type="email" class="form-control" name="email" id="email">
                        <span class="error" v-if="$v.email.$dirty && !$v.email.required">This field is required!</span>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input :class="(($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)) ? 'error-border' : ''" v-model="password" type="password" class="form-control" name="password" id="password" value="">
                        <span class="error" v-if="$v.password.$dirty && !$v.password.required">This field is required!</span>
                        <span class="error" v-if="$v.password.$dirty && !$v.password.minLength">Password must be 8 digits long</span>
                        <span class="error" v-if="invalidLoginDetails">Invalid login credentials</span>
                    </div>
                    <div class="fl-wrap filter-tags clearfix add_bottom_30">
                        <div class="checkboxes float-left">
                            <div class="filter-tags-wrap">
                                <input id="check-b" type="checkbox" name="check">
                                <label for="check-b">Remember me</label>
                            </div>
                        </div>
                        <div class="float-right mt-1"><router-link id="forgot" to="/forgot-password">Forgot Password?</router-link></div>
                    </div>
                    <button style="outline: none;" @click="login()" class="btn_1 rounded full-width res-button-how">Login to Vencasa</button>
                    <div class="text-center add_top_10">New to Find Houses? <strong><router-link to="/register">Sign up!</router-link></strong></div>
                </form>
            </div>
        </div>
    </div>
        </div>
</template>

<script>
import loginMixin from "../mixins/loginMixin";
export default {
    mixins:[
        loginMixin
    ]
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
</style>
